import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ticket = ({ location }) => {
  const { state = {} } = location
  const { destination, place } = state

  // date stuff
  const randomDate = (start, end) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    )
  }

  const addDays = (date, days) => {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }
  const dateToString = (date) => {
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "short" })
    const year = date.getFullYear()
    return `${day} ${month} ${year}`
  }
  const toTitleCase = (str) => {
    if (str !== null) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    }
  }
  // random start date
  const startDate = randomDate(new Date(2050, 0, 1), new Date(2053, 0, 1))

  // ticket data
  const ticketData = {
    dates: [
      {
        soon: addDays(startDate, 3 + ~~(Math.random() * 3)),
        sustainable: addDays(startDate, 5 + ~~(Math.random() * 5)),
        cheap: addDays(startDate, 6 + ~~(Math.random() * 8)),
      },
    ],
    how: [
      {
        fast: [
          {
            title: "IBERIA OC",
            vehicle: "Slow Plane",
            duration: `${4 + ~~(Math.random() * 6)} hours`,
          },
        ],
        enjoyable: [
          {
            title: "IBERIA PANO",
            vehicle: "Train",
            duration: `${9 + ~~(Math.random() * 10)} hours`,
          },
        ],
        cneutral: [
          {
            title: "IBERIA FLOAT",
            vehicle: "Zeppelin",
            duration: `${2 + ~~(Math.random() * 2)} days`,
          },
        ],
      },
    ],
    costs: [
      {
        cc: 200 + ~~(Math.random() * 200),
        work: 10 + ~~(Math.random() * 40),
        cash: 80 + ~~(Math.random() * 120),
      },
    ],
  }

  const ticketUpdate = (but) => {
    but.classList.add("down")
    if (but.id === "soon") {
      const s = dateToString(ticketData.dates[0].soon)
      document.querySelector(".from").innerHTML = s
    }
    if (but.id === "sustainable") {
      const s = dateToString(ticketData.dates[0].sustainable)
      document.querySelector(".from").innerHTML = s
    }
    if (but.id === "cheap") {
      const s = dateToString(ticketData.dates[0].cheap)
      document.querySelector(".from").innerHTML = s
    }
    if (but.id === "fast") {
      const how = ticketData.how[0]
      document.querySelector(".how .title").innerHTML = how.fast[0].title
      document.querySelector(".how .vehicle").innerHTML = how.fast[0].vehicle
      document.querySelector(".how .duration").innerHTML = how.fast[0].duration
      printData.vehicle = how.fast[0].vehicle
      printData.duration = how.fast[0].duration
    }
    if (but.id === "enjoyable") {
      const how = ticketData.how[0]
      document.querySelector(".how .title").innerHTML = how.enjoyable[0].title
      document.querySelector(".how .vehicle").innerHTML =
        how.enjoyable[0].vehicle
      document.querySelector(".how .duration").innerHTML =
        how.enjoyable[0].duration
      printData.vehicle = how.fast[0].vehicle
      printData.duration = how.fast[0].duration
    }
    if (but.id === "cneutral") {
      const how = ticketData.how[0]
      document.querySelector(".how .title").innerHTML = how.cneutral[0].title
      document.querySelector(".how .vehicle").innerHTML =
        how.cneutral[0].vehicle
      document.querySelector(".how .duration").innerHTML =
        how.cneutral[0].duration
      printData.vehicle = how.fast[0].vehicle
      printData.duration = how.fast[0].duration
    }
    if (but.id === "credits") {
      const costs = ticketData.costs[0]
      document.querySelector(".cost .quantity").innerHTML = costs.cc
      document.querySelector(".cost .currency").innerHTML = "CCarbs"
    }
    if (but.id === "work") {
      const costs = ticketData.costs[0]
      document.querySelector(".cost .quantity").innerHTML = costs.work
      document.querySelector(".cost .currency").innerHTML = "Hours"
    }
    if (but.id === "cash") {
      const costs = ticketData.costs[0]
      document.querySelector(".cost .quantity").innerHTML = costs.cash
      document.querySelector(".cost .currency").innerHTML = "BitEuro"
    }
    const downers = document.querySelectorAll(".down")
    if (downers.length > 2) {
      document.getElementById("print").style.display = "block"
    }
  }

  //button function
  const toggleActive = (e) => {
    const but = e.target
    const buts = but.parentElement.querySelectorAll("button")
    let c = 0
    buts.forEach((b) => (b.classList.contains("down") ? c++ : true))
    if (c < 1) {
      ticketUpdate(but)
    } else {
      buts.forEach((b) => b.classList.remove("down"))
      ticketUpdate(but)
    }
  }

  const printData = {
    destination: destination,
    place: place,
    name: "",
    startDate: dateToString(startDate),
    duration: "",
    vehicle: "",
  }

  const sendPrintData = async (event) => {
    const url = `http://192.168.88.236:3000/posto`

    const n = document.getElementById("name").value
    const s = document.getElementById("surname").value

    let passenger = ""
    // if name is empty
    if (n === "" && s === "") {
      passenger = "Anon"
    } else {
      passenger = toTitleCase(`${n} ${s}`)
    }
    printData.name = passenger

    let fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    }

    fetchOptions.body = JSON.stringify(printData)

    fetch(url, fetchOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error))
  }

  return (
    <Ipad data={{ bgColor: "white" }}>
      <div
        style={{
          background: "var(--azul)",
          position: "fixed",
          bottom: "0px",
          left: "0px",
          height: "730px",
          width: "840px",
          zIndex: "-1",
        }}
      />
      <Container>
        <Left>
          <div
            className="topbar"
            style={{ marginBottom: "60px", marginTop: "30px" }}
          >
            <h1>Personaliza tu viaje</h1>
          </div>
          <div className="hbar">
            <h2>tus datos</h2>
            <div className="inputs">
              <input type="text" id="name" placeholder="NAME" />
              <input type="text" id="surname" placeholder="SURNAME" />
            </div>
          </div>
          <div className="hbar">
            <h2>¿CUÁNDO QUIERES VIAJAR?</h2>
            <p>Find the right time based on your beliefs</p>
            <div className="buttons">
              <button id="soon" onClick={toggleActive}>
                As soon as possible
              </button>
              <button id="sustainable" onClick={toggleActive}>
                As sustainable as possible
              </button>
              <button id="cheap" onClick={toggleActive}>
                As cheap as possible
              </button>
            </div>
          </div>
          <div className="hbar">
            <h2>HOW DO YOU WANT TO TRAVEL?</h2>
            <p>The journey can be the destination</p>
            <div className="buttons">
              <button id="fast" onClick={toggleActive}>
                As fast as possible
              </button>
              <button id="enjoyable" onClick={toggleActive}>
                As enjoyable as possible
              </button>
              <button id="cneutral" onClick={toggleActive}>
                As carbon neutral as possible
              </button>
            </div>
          </div>
          <div className="hbar">
            <h2>HOW DO YOU WANT TO PAY?</h2>
            <p>Money is not the only way to travel</p>
            <div className="buttons">
              <button id="credits" onClick={toggleActive}>
                Carbon Credits
              </button>
              <button id="work" onClick={toggleActive}>
                Work to pay
              </button>
              <button id="cash" onClick={toggleActive}>
                Cash
              </button>
            </div>
          </div>
        </Left>
      </Container>

      <Right>
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: "60px",
            }}
          >
            <Link to="/">
              <StaticImage
                height={50}
                layout="fixed"
                src="../images/x.png"
                alt="cross logo"
              />
            </Link>
          </div>
          <p className="azul">Your trip to</p>
          <h1>{destination}</h1>
          <section>
            <h2 className="azul">WHEN</h2>
            <div className="flexi spaced azul-border">
              <p className="from">{dateToString(startDate)}</p>
            </div>
          </section>
          <section className="how">
            <h2 className="azul">HOW</h2>
            <div
              className="flexi spaced azul-border"
              style={{ height: "50px" }}
            >
              <div>
                <p className="title"></p>
                <p className="vehicle"></p>
              </div>
              <div>
                <p className="smol">duration</p>
                <p className="duration"></p>
              </div>
            </div>
          </section>
          <section className="cost">
            <h2 className="azul">COST</h2>
            <div
              className="flexi bottom azul-border"
              style={{ height: "56px" }}
            >
              <p className="quantity"></p>
              <p className="currency"></p>
            </div>
          </section>
        </div>
        <Link to="/floading">
          <button id="print" onClick={sendPrintData}>
            Confirm
          </button>
        </Link>
      </Right>
    </Ipad>
  )
}

const Right = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  background: var(--blu);
  color: white;
  font-family: GEB;
  width: 340px;
  height: 100%;
  h1,
  h2,
  p,
  button,
  input {
    margin: 0;
    outline: none;
  }
  hr {
    border-bottom: 1px solid white;
    margin: 30px 0;
  }
  h1 {
    font-size: 2.8rem;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  h2 {
    font-size: 0.9rem;
    margin-bottom: 7px;
  }
  p {
    font-size: 1.3rem;
  }
  .azul {
    color: var(--azul);
  }
  .azul-border {
    border: 1px solid var(--azul);
    border-radius: 10px;
    padding: 13px;
  }
  .container {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    section {
      margin-bottom: 30px;
      .smol {
        font-family: GL;
        font-size: 0.9rem;
        margin-bottom: 3px;
      }
      .vehicle {
        margin-top: 2px;
      }
      .flexi {
        display: flex;
        .quantity {
          margin-right: 10px;
          margin-top: 16px;
          font-family: GL;
          font-size: 3.5rem;
          line-height: 2.4rem;
        }
      }
      .spaced {
        justify-content: space-between;
      }
      .bottom {
        align-items: flex-end;
      }
    }
  }
  button {
    width: 230px;
    height: 70px;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    border: none;
    background: white;
    color: var(--blu);
    font-family: GEB;
    font-size: 1.5rem;
    border-radius: 4rem;
  }
`

const Left = styled.section`
  width: 750px;
  font-family: GEB;
  .hbar {
    width: 100%;
    margin-bottom: 40px;
    h2 {
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      margin-bottom: 20px;
    }
    input {
      border: 2px solid white;
      padding: 0.9rem 1.4rem;
      margin-right: 20px;
      margin-top: 10px;
      border-radius: 6px;
      color: var(--blu);
      text-transform: uppercase;
      background: transparent;
    }
    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        border: 2px solid white;
        background: transparent;
        color: var(--blu);
        font-family: GEB;
        font-size: 1.1rem;
        border-radius: 4rem;
        width: 230px;
        height: 70px;
      }
      .down {
        background: var(--blu);
        color: white;
        border: none;
      }
    }
  }
`

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  color: var(--blu);
  h1,
  h2,
  p,
  button,
  input {
    margin: 0;
  }
`

export default ticket
